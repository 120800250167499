import React, { useEffect, useRef } from 'react'
import { PlusLine } from './PlusLine'
import { inViewAddClass, lineAnimation } from '../helpers/animations'

export function WhyBlock({ spacer, img1, img2, img3, text }) {
  const rootEl = useRef(null)

  useEffect(() => {
    const line = lineAnimation(rootEl.current)
    const inView = Array.from(rootEl.current.querySelectorAll('[data-in-view]')).map((el) =>
      inViewAddClass(el)
    )

    return () => {
      line?.kill()
      inView?.map((trigger) => trigger?.kill())
    }
  }, [])

  return (
    <section ref={rootEl} className="aboutblock whyblock">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <h2 className="aboutblock__title">
              <span>
                {text.title} <PlusLine reverse />
              </span>
            </h2>

            <p className="aboutblock__subtext">{text.subtext}</p>
          </div>

          <div className="col-md-6">
            <div className="aboutblock__peopleslide fadeInLeft animate" data-in-view>
              <img className="aboutblock__peopleslide--spacer" src={spacer.src} alt={spacer.alt} />
              <img className="aboutblock__peopleslide--img aboutblock__peopleslide--img3" src={img3.src} alt={img3.alt} />
              <img className="aboutblock__peopleslide--img aboutblock__peopleslide--img2" src={img2.src} alt={img2.alt} />
              <img className="aboutblock__peopleslide--img aboutblock__peopleslide--img1" src={img1.src} alt={img1.alt} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
