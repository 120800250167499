import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { PlusLine } from './PlusLine'
import { inViewAddClass, lineAnimation } from '../helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export function WhatBlock({ img1, img2, img3, text }) {
  const rootEl = useRef(null)

  useEffect(() => {
    const line = lineAnimation(rootEl.current)
    const inView = Array.from(rootEl.current.querySelectorAll('[data-in-view]')).map((el) =>
      inViewAddClass(el)
    )

    // image lines
    const l1 = rootEl.current.querySelectorAll('.line-1')
    const l2 = rootEl.current.querySelectorAll('.line-2')

    const tl = gsap
      .timeline({
        defaults: {
          duration: 1.5,
          ease: 'power2.out',
          transformOrigin: '0% 0%',
        },
        scrollTrigger: {
          trigger: rootEl.current,
          start: 'top 85%',
          end: 'bottom 85%',
          once: true,
        },
      })
      .fromTo(l1, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 }, 1)
      .fromTo(l2, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1 }, '-=.75')

    return () => {
      line?.kill()
      inView?.map((trigger) => trigger?.kill())
      tl?.kill()
    }
  }, [])

  return (
    <section ref={rootEl} className="aboutblock whatblock text-right">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 order-md-2">
            <h2 className="aboutblock__title justify-content-end">
              <span>
                {text.title} <PlusLine />
              </span>
            </h2>

            <p
              className="aboutblock__subtext"
              dangerouslySetInnerHTML={{ __html: text.subtext }}
            ></p>
          </div>

          <div className="col-md-6 order-md-1">
            <div className="aboutblock__logowall animate fadeInRight" data-in-view>
              <div className="aboutblock__logowall--logo aboutblock__logowall--logo1 d-flex align-items-center">
                <img className="aboutblock__logowall--img" src={img1.src} alt={img1.alt} />
              </div>

              <div className="aboutblock__logowall--logo aboutblock__logowall--logo2 d-flex align-items-center">
                <img className="aboutblock__logowall--img" src={img2.src} alt={img2.alt} />
              </div>

              <div className="aboutblock__logowall--logo aboutblock__logowall--logo3 d-flex align-items-center">
                <img className="aboutblock__logowall--img" src={img3.src} alt={img3.alt} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
