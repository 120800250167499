import * as React from 'react'
import { Layout } from '../components/Layout'
import { Testimonials } from '../components/Testimonials'
import { injectIntl } from 'gatsby-plugin-intl'
import { WhyBlock } from '../components/WhyBlock'
import { WhatBlock } from '../components/WhatBlock'
import { WhereBlock } from '../components/WhereBlock'
import { WhoBlock } from '../components/WhoBlock'
import { Modal } from '../components/Modal'
import { HubspotForm } from '../components/HubspotForm'

// images
import WhySpacer from '../images/people-slider-spacer.png'
import WhyImg1 from '../images/man-on-phone-image.jpg'
import WhyImg2 from '../images/peopleslide6.jpg'
import WhyImg3 from '../images/peopleslide7.jpg'

import WhatImg1 from '../images/cardano-logo.svg'
import WhatImg2 from '../images/daedalus-logo.svg'
import WhatImg3 from '../images/atala-logo.svg'

// import Who1 from '../images/people1.jpg'
import Who2 from '../images/people2.jpg'
import Who3 from '../images/people3.jpg'
import Who4 from '../images/people4.jpg'
// import Who5 from '../images/people5.jpg'
// import Who6 from '../images/people6.jpg'
import Who7 from '../images/people7.jpg'
// import Who8 from '../images/people8.jpg'
// import Who9 from '../images/people9.jpg'
import Who10 from '../images/people10.jpg'
import Who11 from '../images/people11.jpg'
// import Who12 from '../images/people12.jpg'
import Who13 from '../images/people13.jpg'
import Who14 from '../images/people14.jpg'
// import Who15 from '../images/people15.jpg'

import { graphql } from 'gatsby'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { AnalyticsProvider } from '../analytics/AnalyticsContext'

gsap.registerPlugin(ScrollTrigger)

// markup
const IndexPage = ({ intl, data }) => {
  const [showModal, setShowModal] = React.useState(false)

  React.useEffect(() => {
    if (showModal) {
      document.getElementById('compensateForScrollbarStyles')?.remove()
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
      if (scrollbarWidth > 0) {
        const style = document.createElement('style')
        style.id = 'compensateForScrollbarStyles'
        style.innerHTML = `
          body.modal-open, .modal, .compensate-for-scrollbar {
            padding-right: ${scrollbarWidth}px !important;
          }
        `
        document.head.appendChild(style)
      }
      document.body.classList.add('modal-open')
    } else {
      setTimeout(() => {
        document.getElementById('compensateForScrollbarStyles')?.remove()
        document.body.classList.remove('modal-open')

        setTimeout(() => ScrollTrigger.refresh(), 1)
      }, 100)
    }
  }, [showModal])

  const handleLoad = () => {
    ScrollTrigger.refresh()
  }

  React.useEffect(() => {
    setTimeout(() => {
      handleLoad()
    }, 1)

    window.addEventListener('load', handleLoad)

    return () => {
      window.removeEventListener('load', handleLoad)
    }
  }, [])

  return (
    <Layout data={data}>
      <AnalyticsProvider>
        <Testimonials
          items={[...new Array(16).keys()].map((index) =>
            intl.formatMessage({ id: `testimonial_${index}_text` })
          )}
          currentLanguage={intl.locale}
        />

        <WhyBlock
          spacer={{ alt: '', src: WhySpacer }}
          img1={{ alt: intl.formatMessage({ id: 'why_img_alt' }), src: WhyImg1 }}
          img2={{ alt: '', src: WhyImg2 }}
          img3={{ alt: '', src: WhyImg3 }}
          text={{
            title: intl.formatMessage({ id: 'why_title' }),
            subtext: intl.formatMessage({ id: 'why_subtext' }),
          }}
        />

        <WhatBlock
          img1={{ alt: intl.formatMessage({ id: 'what_img1_alt' }), src: WhatImg1 }}
          img2={{ alt: intl.formatMessage({ id: 'what_img2_alt' }), src: WhatImg2 }}
          img3={{ alt: intl.formatMessage({ id: 'what_img3_alt' }), src: WhatImg3 }}
          text={{
            title: intl.formatMessage({ id: 'what_title' }),
            subtext: intl.formatMessage({ id: 'what_subtext' }),
          }}
        />

        <WhereBlock
          text={{
            title: intl.formatMessage({ id: 'where_title' }),
            subtext: intl.formatMessage({ id: 'where_subtext' }),
          }}
          highlightedCountries={intl.formatMessage({ id: 'where_highlightedCountries' })}
        />

        <WhoBlock
          images={[
            { alt: '', src: Who2 },
            { alt: '', src: Who3 },
            { alt: '', src: Who4 },
            { alt: '', src: Who7 },
            { alt: '', src: Who10 },
            { alt: '', src: Who11 },
            { alt: '', src: Who13 },
            { alt: '', src: Who14 },
          ]}
          text={{
            title: intl.formatMessage({ id: 'who_title' }),
            subtext: intl.formatMessage({ id: 'who_subtext' }),
          }}
          openModal={() => setShowModal(true)}
        />

        <Modal show={showModal} setShow={setShowModal}>
          <div className="modal-body">{intl.formatMessage({ id: 'who_modal_intro' })}</div>
          <HubspotForm submitBtnText={intl.formatMessage({ id: 'who_submit_text' })} />
        </Modal>
      </AnalyticsProvider>
    </Layout>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
