import React, { Fragment, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { SplitText } from 'gsap/SplitText'
import { CustomEase } from 'gsap/CustomEase'
import { IconQuote } from './icons/IconQuote'
import { PlusLine } from './PlusLine'

gsap.registerPlugin(ScrollTrigger, CustomEase)

CustomEase.create(
  'flicker',
  'M0,0,C0,0,0.05,1,0.05,1,0.05,1,0.088,0.146,0.088,0.146,0.088,0.146,0.262,0.788,0.262,0.788,0.262,0.788,0.3,0.18,0.3,0.18,0.3,0.18,0.354,0.47,0.354,0.47,0.354,0.47,0.408,0.026,0.408,0.026,0.408,0.026,0.45,0.784,0.45,0.784,0.45,0.784,0.566,0.162,0.566,0.162,0.566,0.162,0.62,0.912,0.62,0.912,0.62,0.912,0.682,0.394,0.682,0.394,0.682,0.394,1,1,1,1'
)

export const Testimonials = ({ items, currentLanguage }) => {
  const rootEl = useRef(null)
  const animationsStarted = useRef(false)
  const [quotes, setQuotes] = useState(null)

  useEffect(() => {
    if (!quotes || !rootEl.current || animationsStarted.current) return

    animationsStarted.current = true

    // const elements = rootEl.current?.querySelectorAll('.Testimonials__quote blockquote')

    // Array.from(elements).forEach((element) => {
    //   const splitTags = element.querySelectorAll('span, strong')

    //   if (splitTags.length > 0) {
    //     Array.from(splitTags).forEach((tag) => {
    //       const newTags = document.createDocumentFragment()
    //       const tagName = tag.tagName
    //       const classes = tag.className
    //       const words = tag.textContent
    //         .split(' ')
    //         .map((word) => word.trim())
    //         .filter((word) => word !== '')

    //       if (words.length > 1) {
    //         words.forEach((word) => {
    //           const tag = document.createElement(tagName)
    //           tag.className = classes
    //           tag.textContent = word
    //           newTags.appendChild(document.createTextNode(' '))
    //           newTags.appendChild(tag)
    //         })

    //         tag.replaceWith(newTags)
    //       }
    //     })
    //   }
    // })

    // new SplitText(elements, { type: 'words' })

    const quoteNodes = rootEl.current?.querySelectorAll('.Testimonials__quote')

    const tlAll = gsap.timeline({
      repeat: -1,
      repeatDelay: 1,
      scrollTrigger: {
        trigger: rootEl.current,
        toggleActions: 'play pause resume pause',
        onToggle: ({ isActive }) => {
          if (isActive) {
            gsap.set(rootEl.current, { opacity: 1 })
          } else {
            gsap.set(rootEl.current, { opacity: 0 })
          }
        },
        markers: false,
      },
    })

    Array.from(quoteNodes).forEach((el) => {
      const tl = gsap.timeline({
        defaults: {},
      })

      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

      const i = el.querySelector('.Testimonials__quote-inner')
      const b = el.querySelector('blockquote')

      // calculate reading time
      let readingTime = 5

      if (currentLanguage === 'ja') {
        const text = b.textContent.trim()

        readingTime = Math.max(Math.floor((text.length * 60) / 400), 3.5)
      } else {
        const words = b.textContent
          .split(' ')
          .map((word) => word.trim())
          .filter((word) => word !== '')

        readingTime = Math.max(Math.floor((words.length * 60) / 160), 3.5)
      }

      if (el.dataset.layout === 'layout-1') {
        const q1 = el.querySelector('.quote-1')
        const q2 = el.querySelector('.quote-2')
        const l1 = el.querySelector('.line-1')
        const l2 = el.querySelector('.line-2')

        tl.set([q1, l1], { opacity: 0, x: Math.min(vw / 3, 400), y: 75 })
          .set([q2, l2], { opacity: 0, x: -Math.min(vw / 3, 400), y: -75 })
          .set(b, { opacity: 0, scale: 0.25 })
          .set(i, { opacity: 1, scale: 2, visibility: 'visible' })

          .to(i, { scale: 1.5, duration: 1, ease: 'power3.out' }, 0)
          .to([l1, l2], { opacity: 1, duration: 1.5, ease: 'power1.out' }, 0)
          .to([q1, q2], { opacity: 1, duration: 1, ease: 'flicker' }, 0)
          .to(i, { scale: 1, duration: 1, ease: 'power3.out' }, 0.9)
          .to([l1, l2, q1, q2], { x: 0, y: 0, duration: 1, ease: 'power3.out' }, 1)
          .to(b, { opacity: 1, scale: 1, duration: 1, ease: 'power3.out' }, 1)

          .to(i, { x: 10, y: 10, scale: 1.025, duration: readingTime, ease: 'power1.inOut' })

          .to([q1, l2], { opacity: 0, duration: 1, ease: 'flicker' }, '-=1')
          .to(
            i,
            {
              opacity: 0,
              rotate: 60,
              scale: 0,
              duration: 0.5,
              ease: 'power1.in',
            },
            '-=.25'
          )
          .set([i, b, l1, l2, q1, q2], { clearProps: 'all' })
      } else if (el.dataset.layout === 'layout-2') {
        const l1 = el.querySelector('.line-1')
        const l2 = el.querySelector('.line-2')

        tl.set(l1, { opacity: 0, y: 100 })
          .set(l2, { opacity: 0, x: vw / 2 })
          .set(b, { opacity: 0, x: 200, skewX: 25 })
          .set(i, { opacity: 1, visibility: 'visible' })

          .to(l1, { opacity: 1, duration: 1, ease: 'flicker' }, 0)
          .to(l1, { y: 0, ease: 'power1.out' }, 1)
          .to(l2, { opacity: 1, x: 0, duration: 1, ease: 'power1.out' }, 0.75)
          .to(b, { opacity: 1, x: 0, skewX: 0, duration: 0.75, ease: 'power1.out' }, 1)

          .to(i, { y: 10, scale: 1.025, duration: readingTime, ease: 'power1.inOut' })

          .to(l2, { opacity: 0, duration: 1, ease: 'flicker' }, '-=1')
          .to(
            i,
            {
              opacity: 0,
              rotate: -60,
              scale: 0,
              duration: 0.5,
              ease: 'power1.in',
            },
            '-=.25'
          )
          .set([i, b, l1, l2], { clearProps: 'all' })
      } else if (el.dataset.layout === 'layout-3') {
        const q1 = el.querySelector('.quote-1')
        const q2 = el.querySelector('.quote-2')
        const l1 = el.querySelector('.line-1')
        const l2 = el.querySelector('.line-2')

        tl.set([q1, l1], { x: 340, y: -50 })
          .set([q2, l2], { x: -340, y: 50 })
          .set(i, { opacity: 0, scale: 1.25, visibility: 'visible' })

          .to(i, { opacity: 1, scale: 1, duration: 1, ease: 'power3.out' }, 0)
          .to([l1, l2, q1, q2], { x: 0, y: 0, duration: 1, ease: 'power3.out' }, 0)
          .set([q1, q2], { opacity: 0 }, 0.5)
          .to([q1, q2], { opacity: 1, duration: 1, ease: 'flicker' }, 0.5)

          .to(i, { scale: 1.025, duration: readingTime - 1, ease: 'power1.inOut' })

          .to([q1, q2], { opacity: 0, duration: 1, ease: 'flicker' }, 'leave')
          .to([q1, l1], { opacity: 0, x: -1000, duration: 1, ease: 'power3.in' }, 'leave+=.5')
          .to([q2, l2], { opacity: 0, x: 1000, duration: 1, ease: 'power3.in' }, 'leave+=.5')
          .to(
            i,
            { opacity: 0, rotate: -45, scale: 0, duration: 0.5, ease: 'power1.in' },
            'leave+=1'
          )
          .set([i, l1, l2, q1, q2], { clearProps: 'all' })
      }

      tlAll.add(tl)
    })

    return () => tlAll?.kill()
  }, [quotes, currentLanguage])

  useEffect(() => {
    const parsedQuotes = items
      .map((each) => each.split('||').map((each) => each.trim()))
      .map((each) => ({ layout: each[0], quote: each[1] }))

    setQuotes([...parsedQuotes])
  }, [items])

  return (
    <section ref={rootEl} className="Testimonials">
      <div className="container">
        <div className="Testimonials__container">
          {quotes &&
            quotes.map(({ layout, quote }, index) => (
              <div
                key={index}
                className={`Testimonials__quote Testimonials__quote--${layout}`}
                data-layout={layout}
              >
                <div className="Testimonials__quote-inner">
                  {layout === 'layout-1' && (
                    <Fragment>
                      <PlusLine vertical className="line-1" />
                      <IconQuote className="quote-1" />

                      <blockquote dangerouslySetInnerHTML={{ __html: quote }}></blockquote>

                      <IconQuote close className="quote-2" />
                      <PlusLine className="line-2" />
                    </Fragment>
                  )}

                  {layout === 'layout-2' && (
                    <Fragment>
                      <PlusLine vertical className="line-1" />

                      <blockquote dangerouslySetInnerHTML={{ __html: quote }}></blockquote>

                      <PlusLine className="line-2" />
                    </Fragment>
                  )}

                  {layout === 'layout-3' && (
                    <Fragment>
                      <PlusLine reverse className="line-1" />
                      <IconQuote className="quote-1" />

                      <blockquote dangerouslySetInnerHTML={{ __html: quote }}></blockquote>

                      <IconQuote close className="quote-2" />
                      <PlusLine className="line-2" />
                    </Fragment>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
