import React, { useEffect, useRef } from 'react'

export const Modal = ({ children, show, setShow }) => {
  const modalEl = useRef(null)
  const backdropEl = useRef(null)

  useEffect(() => {
    if (show) {
      backdropEl.current.style.display = 'block'
      modalEl.current.style.display = 'block'

      setTimeout(() => {
        backdropEl.current.classList.add('show')
        modalEl.current.classList.add('show')
      }, 1)
    } else {
      backdropEl.current.classList.remove('show')
      modalEl.current.classList.remove('show')

      setTimeout(() => {
        backdropEl.current.style.display = 'none'
        modalEl.current.style.display = 'none'
      }, 300)
    }
  }, [show])

  return (
    <>
      <div
        ref={modalEl}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        style={{ display: 'none' }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-button d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => setShow(false)}
              ></button>
            </div>

            {children}
          </div>
        </div>
      </div>

      <div
        ref={backdropEl}
        className="modal-backdrop fade"
        style={{ display: 'none' }}
        // onClick={() => setShow(false)}
      />
    </>
  )
}
