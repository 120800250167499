import React, { useEffect, useRef } from 'react'
import { PlusLine } from './PlusLine'
import { inViewAddClass, lineAnimation } from '../helpers/animations'
import { Button } from '../components/Button'
import { useIntl } from 'gatsby-plugin-intl'

export function WhoBlock({ images, text, openModal }) {
  const intl = useIntl()
  const rootEl = useRef(null)

  useEffect(() => {
    const line = lineAnimation(rootEl.current)
    const inView = Array.from(rootEl.current.querySelectorAll('[data-in-view]')).map((el) =>
      inViewAddClass(el)
    )

    return () => {
      line?.kill()
      inView?.map((trigger) => trigger?.kill())
    }
  }, [])

  return (
    <section ref={rootEl} className="aboutblock whoblock text-right">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6 order-md-2">
            <h2 className="aboutblock__title justify-content-end">
              <span>
                {text.title} <PlusLine />
              </span>
            </h2>

            <p
              className="aboutblock__subtext"
              dangerouslySetInnerHTML={{ __html: text.subtext }}
            ></p>

            <Button
              href="#"
              text={intl.formatMessage({ id: 'who_btn_text' })}
              clickEvent={(event) => {
                event.preventDefault()
                openModal()
              }}
            />
          </div>

          <div className="col-md-6 order-md-1">
            <div className="aboutblock__photowall animate fadeInRight" data-in-view>
              {images.map(({ src, alt }, i) => {
                return (
                  <img
                    key={src}
                    className={`aboutblock__photowall--img aboutblock__photowall--img${i + 1}`}
                    src={src}
                    alt={alt}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
