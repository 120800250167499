import React from 'react'

export const IconQuote = ({ close = false, className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.92 48.01"
      className={`IconQuote ${className}`}
      style={{ overflow: 'visible' }}
      aria-hidden="true"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth={1}
        style={{
          transform: `${close ? 'rotate(180deg)' : 'none'}`,
          transformOrigin: '50% 50%',
        }}
        d="M19.35,27.1V47.51H.5V31.4q0-13.09,3.12-18.95A26.36,26.36,0,0,1,16.61.63l4.3,6.84A15.74,15.74,0,0,0,13,14.16Q10.47,18.6,10.17,27.1Zm30.27,0V47.51H30.77V31.4q0-13.09,3.13-18.95A26.28,26.28,0,0,1,46.89.63l4.29,6.84a15.78,15.78,0,0,0-7.91,6.69Q40.74,18.6,40.44,27.1Z"
      />
    </svg>
  )
}
