import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { WorldMap } from './WorldMap'
import { PlusLine } from './PlusLine'
import { inViewAddClass, lineAnimation } from '../helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const WhereBlock = ({ text, highlightedCountries }) => {
  const rootEl = useRef(null)
  const mapEl = useRef(null)

  useEffect(() => {
    const line = lineAnimation(rootEl.current)
    const inView = Array.from(rootEl.current.querySelectorAll('[data-in-view]')).map((el) =>
      inViewAddClass(el)
    )

    return () => {
      line?.kill()
      inView?.map((trigger) => trigger?.kill())
    }
  }, [])

  useEffect(() => {
    const countries = highlightedCountries.split(',').map((country) => country.trim())

    const uniqueCountries = [...new Set(countries)]

    const elements = uniqueCountries
      .map((country) => {
        let node = mapEl.current.querySelectorAll(`[class="${country}"]`)

        if (!node.length) {
          node = mapEl.current.querySelectorAll(`[name="${country}"]`)
        }

        return [...node]
      })
      .filter((country) => country.length)

    const mapTl = gsap.timeline({
      defaults: {
        fill: '#ec1c24',
        duration: 0.5,
        ease: 'power1.inOut',
      },
      scrollTrigger: {
        trigger: mapEl.current,
        start: 'top 75%',
        end: 'bottom 75%',
        once: true,
        markers: false,
      },
      onComplete: () => {
        mapTl.kill()
      },
    })

    gsap.utils.shuffle(elements).map((country, index) => mapTl.to(country, {}, index * 0.15))

    return () => mapTl.kill()
  }, [highlightedCountries])

  return (
    <section ref={rootEl} className="WhereBlock aboutblock">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <h2 className="aboutblock__title">
              <span>
                {text.title} <PlusLine reverse />
              </span>
            </h2>

            <p className="aboutblock__subtext">{text.subtext}</p>
          </div>

          <div className="col-md-6">
            <div
              ref={mapEl}
              className="WhereBlock__map animate fadeInLeft"
              dangerouslySetInnerHTML={{ __html: WorldMap }}
              data-in-view
            ></div>
          </div>
        </div>
      </div>
    </section>
  )
}
