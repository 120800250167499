import React from 'react'
import classNames from 'classnames'

export const PlusLine = ({ vertical = false, reverse = false, className = '' }) => {
  return (
    <div
      className={classNames(
        'PlusLine',
        {
          'PlusLine--vertical': vertical,
          'PlusLine--reverse': reverse,
        },
        className
      )}
      aria-hidden="true"
    >
      <div className="PlusLine__plus"></div>
      <div className="PlusLine__line"></div>
    </div>
  )
}
