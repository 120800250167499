import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import axios from 'axios'

export const HubspotForm = () => {
  const intl = useIntl()
  const [successMessage, setSuccessMessage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const stripHtml = (html) => {
    var temporalDivElement = document.createElement('div')
    temporalDivElement.innerHTML = html
    return temporalDivElement.textContent || temporalDivElement.innerText || ''
  }

  const validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const validateField = ({ input, name, value }) => {
    switch (name) {
      case 'email':
        if (value.length > 0 && validateEmail(value)) {
          input.classList.remove('is-invalid')
          return false
        } else {
          input.classList.add('is-invalid')
          return true
        }
      default:
        if (value.length > 0) {
          input.classList.remove('is-invalid')
          return false
        } else {
          input.classList.add('is-invalid')
          return true
        }
    }
  }

  const handleChange = (event) => {
    validateField({
      input: event.target,
      name: event.target.getAttribute('name'),
      value: stripHtml(event.target.value).trim(),
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    setIsSubmitting(true)

    const inputs = [...event.target.querySelectorAll('[name]')].map((input) => ({
      input,
      name: input.getAttribute('name'),
      value: stripHtml(input.value).trim(),
    }))

    const errors = inputs.map(validateField).filter((error) => error)

    console.log(errors)

    if (errors.length) {
      setIsSubmitting(false)

      return
    }

    try {
      await axios.post(
        'https://api.hsforms.com/submissions/v3/integration/submit/8848114/57df3467-03bf-46af-a1de-dd5c0d49ee7d',
        {
          fields: inputs.map(({ name, value }) => ({ name, value })),
        }
      )

      setSuccessMessage(intl.formatMessage({ id: 'who_success_message' }))

      setTimeout(() => {
        event.target.reset()
        setSuccessMessage(false)
        setIsSubmitting(false)
      }, 5000)
    } catch (error) {
      alert(error.response.data.message)
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <input
          type="text"
          name="name"
          placeholder={intl.formatMessage({ id: 'who_modal_name' })}
          className="form-control"
          onBlur={handleChange}
          onChange={handleChange}
        />

        <input
          type="email"
          name="email"
          placeholder={intl.formatMessage({ id: 'who_modal_email' })}
          className="form-control"
          onBlur={handleChange}
          onChange={handleChange}
        />

        <select
          name="enquiry_type"
          className="form-control custom-select"
          onBlur={handleChange}
          onChange={handleChange}
        >
          <option value="">-- {intl.formatMessage({ id: 'who_modal_etype' })} --</option>
          <option>{intl.formatMessage({ id: 'who_modal_general' })}</option>
          <option>{intl.formatMessage({ id: 'who_modal_media' })}</option>
          <option>{intl.formatMessage({ id: 'who_modal_investor' })}</option>
          <option>{intl.formatMessage({ id: 'who_modal_marketing' })}</option>
          <option>{intl.formatMessage({ id: 'who_modal_technology' })}</option>
          <option>{intl.formatMessage({ id: 'who_modal_research' })}</option>
        </select>

        <textarea
          name="message"
          placeholder={intl.formatMessage({ id: 'who_modal_message' })}
          className="form-control"
          rows="5"
          onBlur={handleChange}
          onChange={handleChange}
        />

      </div>

      <div className="modal-footer">
        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
          {intl.formatMessage({ id: 'who_submit_text' })}
        </button>

        {successMessage && <p className="color-success">{successMessage}</p>}

      </div>
    </form>
  )
}
