import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export const lineAnimation = (el) => {
  if (!el) return

  const lineEl = el.querySelector('.PlusLine')
  const linePlusEl = el.querySelector('.PlusLine__plus')
  const isReversed = lineEl.classList.contains('PlusLine--reverse')

  return gsap
    .timeline({
      defaults: {
        duration: 1.5,
        ease: 'power3.out',
      },
      scrollTrigger: {
        trigger: lineEl,
        start: 'top 85%',
        end: 'bottom 85%',
        once: true,
      },
    })
    .fromTo(lineEl, { opacity: 0, x: isReversed ? '-50vw' : '50vw' }, { opacity: 1, x: 0 }, 0)
    .fromTo(linePlusEl, { rotate: 0 }, { rotate: isReversed ? 360 : -360 }, 0)
    .set(linePlusEl, { opacity: 0 })
    .to(linePlusEl, { opacity: 1, duration: 1, ease: 'flicker' })
}

export const inViewAddClass = (el) => {
  if (!el) return

  return ScrollTrigger.create({
    trigger: el,
    start: 'top 85%',
    end: 'bottom 85%',
    once: true,
    onEnter: () => {
      el.classList.add('is-in-view')
    },
  })
}
